import React from 'react'
import './Phone.scss'
import Select from '../Select/Select'
import Input from '../Input/Input'
import { IMG_BASE_URL } from '../../../config'
import { useSelector } from 'react-redux'

export default function Phone({ css, label, field, validation, className, inputSocket, countryCode_inputSocket, error, noField, onFocus, noForm, required, countryCode_field, onBlur, width, icon, onSubmit, onChange, value, countryCode_onChange, countryCode_value, labelIcon, onLabelIconClick, icon2, onIconClick2, iconHint, icon2Hint }) {

    const flags = useSelector(s => s.flags)

    return (
        <div id='Phone' className={className}>
            <Input
                css={css}
                label={label}
                field={field}
                type='number'
                validation={validation}
                inputSocket={inputSocket}
                onBlur={onBlur}
                onFocus={onFocus}
                width={350}
                icon={icon}
                value={value}
                onSubmit={onSubmit}
                onChange={onChange}
                noField={noField}
                noForm={noForm}
                labelIcon={labelIcon}
                onLabelIconClick={onLabelIconClick}
                required={required}
                error={error}
                icon2={icon2}
                onIconClick2={onIconClick2}
                iconHint={iconHint}
                icon2Hint={icon2Hint}
            />
            <Select
                css={1}
                mt={28}
                field={countryCode_field || 'country_code'}
                content={{
                    op: flags,
                    vl: 'code',
                    lb: (e) => <p className='cntr_flag_cd'>
                        <img width={30} height={20} src={`${IMG_BASE_URL}${'flags'}/${e.flag}`} alt="" />
                        <p className='country_code'>{e.code}</p>
                    </p>
                }}
                inputSocket={countryCode_inputSocket}
                onChange={countryCode_onChange}
                value={countryCode_value}
                height={43}
                placeholder={' '}
            />
        </div>
    )
}
