import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { toast as toaster } from 'react-toastify'
import { CommonContext, axiosInstance, updateData, updateLoader, updatePayload } from '..'
import config from '../../common/config'

let toastOptions = { position: "bottom-center", autoClose: 3000 }

const accept = (obj, arr) => {
  let res = {}
  arr.forEach((item) => {
    Object.assign(res, { [item]: obj[item] })
  })
  return res
}

const except = (obj, arr) => {
  arr.forEach((item) => {
    delete obj[item]
  })
  return obj
}

function ArrFormatting(arr, cb) {
  if (!Array.isArray(arr)) console.error('TypeError\:\xA0Field passed\xA0into arr()\xA0function in\xA0useFetch >>\xA0formatting is\xA0not an `Array` ')
  else {
    let obj = arr.map((a, i) => {
      let res = cb(a, i)
      return { ...res }
    }).reduce((a, b, i) => {
      let key = Object.keys(b)[0]
      let field = key
      if (key === undefined) return a
      if (key in a) { key += 'useFetch' + i }
      return { ...a, ...{ [key]: b[field] } }
    }, {})
    return { ...obj }
  }
}

export default function useFetch({ name, method = 'get', endpoint = '', type, baseUrl, toast, privateLoader, selfCall, download, headers, success, failure, dependency = [] }) {

  const dispatch = useDispatch()

  const { setAppLoader, apiHeaders } = useContext(CommonContext)

  const key = method + '/' + endpoint
  const storeData = useSelector(s => s.data[name || key])
  const storePayload = useSelector(s => s.payload[name || key])
  baseUrl = config?.__api_base_url || 'http://localhost:8000/'

  const [data, setData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [progress, setProgress] = useState(null)

  function uploadProgressHandler(e) {
    let value = Math.round(e.progress * 100)
    setProgress(value === 100 ? null : value)
  }

  useEffect(() => {
    selfCall && (selfCall === true ? fetcher() : fetcher(selfCall))
  }, [...dependency])

  async function fetcher(arg1, arg2, arg3) {
    let cb
    let options
    if (arg2 instanceof Function) cb = arg2
    if (arg3 instanceof Function) cb = arg3
    if (typeof arg2 === 'object') options = arg2
    if (loader) return
    setLoader(true)
    setProgress(1)
    privateLoader = options?.privateLoader === undefined ? privateLoader : options?.privateLoader
    !privateLoader && setAppLoader(s => s + 1)
    dispatch(updateLoader({ key, value: true }))
    let PD = arg1
    if (typeof arg1 === 'string') PD = [arg1]
    if (arg1 instanceof Function) PD = { ...PD, ...arg1(ArrFormatting) }
    if (options?.formatting) PD = { ...PD, ...options.formatting(PD, ArrFormatting) }
    if (options?.accept) PD = accept(PD, options.accept)
    if (options?.except) PD = except(PD, options.except)
    if (type === 'formdata' && PD) {
      let formdata = new FormData()
      Object.keys(PD).forEach(item => {
        formdata.append(item.replace(/useFetch\d*/, ""), PD[item] === undefined ? '' : PD[item])
      })
      PD = formdata
    }
    const apiOptions = {
      // withCredentials: true,
      headers: Object.values(headers || {}).filter(i => i).length > 0 ? headers : apiHeaders,
      onUploadProgress: uploadProgressHandler,
      ...(download ? { responseType: 'arraybuffer' } : {}),
    }
    let response;
    if (false && _.isEqual(PD, storePayload) && storeData) {
      response = { status: true, data: storeData, code: 200, message: '' }
      setLoader(false)
      !privateLoader && setAppLoader(s => s - 1)
      dispatch(updateLoader({ key, value: false }))
      setData(response.data)
      cb && cb(response)
    } else {
      await (
        method.toLowerCase() === 'get'
          ? axiosInstance.get(baseUrl + endpoint + (
            type === 'params'
              ? ('/' + (PD ? Object.values(PD) : []).join('/'))
              : type === 'query' ? ('?' + Object.entries(PD).flatMap(([k, v]) => v ? (k + '=' + v) : []).join('&')) : ''
          ), apiOptions)
          : type === 'params'
            ? axiosInstance[method](baseUrl + endpoint + (type === 'params' ? ('/' + (Object.values(PD)).join('/')) : ''), null, apiOptions)
            : axiosInstance[method](baseUrl + endpoint, PD, apiOptions)
      )
        .then((res) => {
          if (download && !res.headers?.['content-type'].includes('application/json')) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url
            link.download = res.headers?.['content-disposition']?.split('filename=')[1] || 'download'
            link.click();
          } else {
            response = res.data
            setData(response)
            dispatch(updateData({ key, value: response.data }))
            dispatch(updatePayload({ key, value: _.cloneDeep(PD) }))
            toast && toaster.success(response.message, toastOptions)
          }
          try {
            cb && cb(response)
            success && response.status && success(response)
            failure && !response.status && failure(response)
          }
          catch (err) {
            failure && failure(response)
            console.error(err)
          }
        })
        .catch((err) => {
          response = { ...err.response?.data, code: err.response?.status }
          if (err.code === "ERR_NETWORK") { response = { message: err.message, status: false } }
          setData(null)
          toast && toaster.error(response?.message, toastOptions)
          cb && cb(response)
        })
        .finally(() => {
          setLoader(false)
          setProgress(null)
          !privateLoader && setAppLoader(s => s - 1)
          dispatch(updateLoader({ key, value: false }))
        })
    }
    return response
  }

  return { req: fetcher, res: data, lod: loader, pro: progress }
}