import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Grid from '../../_common/_components/Grid/Grid'
import { CALL_BASE_URL, IMG_BASE_URL } from '../../config'
import useFetch from '../../_common/_Hooks/useFetch'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../../_common/_components/Input/Input'
import TextArea from '../../_common/_components/TextArea/TextArea'
import Flex from '../../_common/_components/Flex/Flex'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import Button from '../../_common/_components/Button/Button'
import useForm from '../../_common/_Hooks/useForm'
import DatePick from '../../_common/_components/DatePick/DatePick'
import FileUpload from '../../_common/_components/FileUpload/FileUpload'
import List from '../../_common/_components/List/List'
import Image from '../../_common/_components/Image/Image'
import Files from '../../_common/_components/Files/Files'
import useTranslate from '../../_common/_Hooks/useTranslate'
import { useSelector } from 'react-redux'
import useModal from '../../_common/_Hooks/useModal'
import { Confirm_with_comment } from '../../common/Modals'
import Select from '../../_common/_components/Select/Select'
import axios from 'axios'
import VoicePlayer from '../../components/VoicePlayer/VoicePlayer'
import Phone from '../../_common/_components/Phone/Phone'

export default function LR_view_request() {

    const { id } = useParams()
    const { _lang, lang, t } = useTranslate()
    const navigate = useNavigate()
    const m_confirm = useModal(<Confirm_with_comment />)
    const user = useSelector(s => s.user)

    const Emirates = [
        { english: "Abu Dhabi", arabic: "أبو ظبي" },
        { english: "Dubai", arabic: "دبي" },
        { english: "Sharjah", arabic: "الشارقة" },
        { english: "Ajman", arabic: "عجمان" },
        { english: "Umm Al Quwain", arabic: "أم القيوين" },
        { english: "Ras Al Khaimah", arabic: "رأس الخيمة" },
        { english: "Fujairah", arabic: "الفجيرة" }
    ];

    const statuses = useSelector(s => s.statuses)

    // *********************************************************************************************************************
    const { req: uploadFiles, lod: fileUploadLoader, } = useFetch({
        endpoint: 'file/uploadFile',
        method: 'post',
        type: 'formdata',
        toast: false,
        privateLoader: true
    })
    // *********************************************************************************************************************

    const { setInputs, inputs, formPlug, validate, inputHandler } = useForm({
        country_code: {
            "_id": "667e5754a017a0408a9f1f5b",
            "country": "United Arab Emirates",
            "handle_key": "united_arab_emirates",
            "code": "+971",
            "flag": "united_arab_emirates.svg"
        },
    }, { fileUploader: uploadFiles })

    // *********************************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'lecture-request/getRequestDetails',
        method: 'get',
        type: 'params',
        selfCall: id,
        success: (res) => {
            let res_data = res.data.details
            setInputs({
                coordinator_name: res_data.coordinator_name,
                contact_number: res_data.contact_number,
                country_code: res_data.customer_contact_code,
                email: res_data.email,
                request_from: new Date(res_data.request_from),
                request_to: new Date(res_data.request_to),
                participant_no: res_data.participant_no,
                emirates: res_data.emirates,
                beneficiary: res_data.beneficiary,
            })
        }
    })
    // *********************************************************************************************************************
    const { req: changeStatus } = useFetch({
        endpoint: 'lecture-request/changeRequestStatus',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: review } = useFetch({
        endpoint: 'lecture-request/approveOrReject',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const { res: history_res } = useFetch({
        endpoint: 'lecture-request/getLectureRequestHistory',
        method: 'post',
        type: 'raw',
        selfCall: { id }
    })
    // *********************************************************************************************************************
    const details = details_res?.data.details
    const history = history_res?.data
    // *********************************************************************************************************************
    const isTo_be_reviewed = details?.status.handle_key === 'to_be_reviewed'
    const isNot_responded = details?.status.handle_key === 'not_responded'
    const isRejected = details?.status.handle_key === 'rejected'
    const isConfirmed = details?.status.handle_key === 'confirm'
    const isCancelled = details?.status.handle_key === 'cancelled'
    const isCompleted = details?.status.handle_key === 'completed'
    // *********************************************************************************************************************
    const ButtonConditions = {
        NotResponded: isTo_be_reviewed || isNot_responded,
        Reject: isTo_be_reviewed || isNot_responded,
        Confirm: isTo_be_reviewed || isNot_responded,
        Cancel: isConfirmed,
        Completed: isConfirmed,
    }
    // *********************************************************************************************************************
    const SectionConditions = {
        Edit_CoordinatorDetails: isTo_be_reviewed || isNot_responded,
        Edit_LectureDetails: isTo_be_reviewed || isNot_responded,
        comment: isTo_be_reviewed || isNot_responded || isConfirmed,
        attachments: isTo_be_reviewed || isNot_responded || isConfirmed,
    }
    // *********************************************************************************************************************

    async function submitHandler(action) {
        if (!await validate()) return
        const { close, cancel, m_inputs } = await m_confirm.show({ action, m_inputs: inputs })
        if (close || cancel) return
        let obj = {
            id,
            coordinator_name: inputs.coordinator_name,
            contact_number: inputs.contact_number,
            customer_contact_code: inputs.country_code.code,
            email: inputs.email,
            request_from: inputs.request_from,
            request_to: inputs.request_to,
            participant_no: inputs.participant_no,
            emirates: inputs.emirates,
            beneficiary: inputs.beneficiary,
            comment: m_inputs.comment,
            status: action._id,
            attachments: inputs.attachments_filenames,
            track_id: inputs.track_id,
        }
        if (['not_responded', 'rejected', 'confirm'].includes(action.handle_key))
            review(obj, () => { navigate('/lecuture_request/requests') })
        if (['cancelled', 'completed'].includes(action.handle_key))
            changeStatus(obj, () => { navigate('/lecuture_request/requests') })
    }

    function callHandler(number, ticket_no) {
        const formdata = new FormData()
        formdata.append('phone_number', number.replace('+','00'))
        formdata.append('agent_id', user?.phone_number)
        formdata.append('ticket_id', ticket_no)
        formdata.append('agent_name', user?.employee_id)
        formdata.append('type', 'lecture_request')
        axios.post(CALL_BASE_URL, formdata)
    }

    return (
        <Page>
            <Page.Header>
                <Title />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Request Details' toggleButton titleEndElement={<StatusBox css={1} status={details?.status?.request_status} />} >
                    <Grid columns={4}>
                        <LabelValue label='Request Number' value={details?.request_no} />
                        <LabelValue label='Date & Time' value={<DateViewer dateTime={details?.createdAt} />} />
                        <LabelValue css={1} label='Customer Contact' value={details?.contact_number ? ((details?.customer_contact_code || '+971') + ' ' + details?.contact_number) : '-'} isHyperlink className='customer_contact'
                            valueIcon={'/assets/icons/call-green-round.svg'} valueIconHint='Call Customer'
                            onIconClick={() => callHandler((details?.customer_contact_code || '+971') + details?.contact_number, details?.request_no)} />
                    </Grid> <hr />
                    <Grid columns={5}>
                        {/* <LabelValue label='Precise Location' value={details?.precise_location} /> */}
                        <LabelValue label='IP Address' value={details?.ip_addr} />
                        <LabelValue label='Device' value={t(details?.device)} className='lr_device' />
                        <LabelValue label='Device ID' value={details?.device_id} />
                    </Grid>
                </TitleBar>

                {SectionConditions.Edit_CoordinatorDetails && <TitleBar css={1} title='Coordinator Details' toggleButton>
                    <Flex columns={5} gap={15} _minWidth={200}>
                        <Input css={1} label='Coordinator Name' field='coordinator_name' inputSocket={formPlug} />
                        {/* <Input css={1} label='Contact Number' field='contact_number' type='number' validation={{ phone: { digits: "6-13" }, maxNumber: 13 }} inputSocket={formPlug} /> */}
                        <Phone
                            css={1}
                            label='Phone Number'
                            field='contact_number'
                            countryCode_field='country_code'
                            type='number'
                            validation={{ phone: { digits: "6-13" }, maxNumber: 13 }}
                            inputSocket={formPlug}
                            countryCode_inputSocket={formPlug}
                        // onBlur={customerFindHandler}
                        />
                        <Input css={1} label='Email' field='email' inputSocket={formPlug} />
                    </Flex>
                </TitleBar>}

                {!SectionConditions.Edit_CoordinatorDetails && <TitleBar css={1} title='Coordinator Details' toggleButton>
                    <Flex columns={5} gap={15} _minWidth={200}>
                        <LabelValue label='Coordinator Name' value={details?.coordinator_name} />
                        <LabelValue dir='ltr' className='lr_contact' label='Contact Number' value={details?.contact_number ? ((details?.customer_contact_code || '+971') + ' ' + details?.contact_number) : '-'} />
                        <LabelValue label='Email' value={details?.email} />
                    </Flex>
                </TitleBar>}

                {SectionConditions.Edit_LectureDetails && <TitleBar css={1} title='Lecture Details' toggleButton >
                    <Flex columns={4} gap={15} _minWidth={200}>
                        <DatePick css={1} timeSelect label='Lecture From' field='request_from' inputSocket={formPlug} onChange={(e, k) => {
                            inputHandler(e, k)
                            setInputs(s => ({ ...s, request_to: '' }))
                        }} />
                        <DatePick css={1} timeSelect label='Lecture To' field='request_to' inputSocket={formPlug}
                            minDate={inputs.request_from}
                            minTime={new Date(inputs.request_to).setHours(0, 0, 0) === new Date(inputs.request_from).setHours(0, 0, 0) ? inputs.request_from : new Date().setHours(0, 0, 0)}
                            maxTime={new Date().setHours(23, 59, 59)} />
                        <Input css={1} label='No of Participants' field='participant_no' inputSocket={formPlug} />
                        <Select css={1} label='Emirates' field='emirates' inputSocket={formPlug} content={{ op: Emirates, vl: 'english', lb: lang === 'ar' ? 'arabic' : 'english' }} />
                    </Flex>
                    <Input css={1} mt={20} label='Beneficiary' field='beneficiary' inputSocket={formPlug} />
                </TitleBar>}

                {!SectionConditions.Edit_LectureDetails && <TitleBar css={1} title='Lecture Details' toggleButton >
                    <Flex columns={5} gap={15} _minWidth={200}>
                        <LabelValue label='Lecture From' value={<DateViewer dateTime={details?.request_from} />} />
                        <LabelValue label='Lecture To' value={<DateViewer dateTime={details?.request_to} />} />
                        <LabelValue label='No of Participants' value={details?.participant_no} />
                        <LabelValue label='Emirates' value={details?.emirates[lang === 'ar' ? 'arabic' : 'english']} />
                        <LabelValue label='Beneficiary' value={details?.beneficiary} />
                    </Flex>
                </TitleBar>}

                {history?.length > 0 && <TitleBar css={1} title='Activity Log' toggleButton>
                    <List css={1} items={history}>
                        {(item) => {
                            return (
                                <>
                                    <List.Bullet src='/assets/icons/history2.svg' />
                                    <Image className='user_img' src={`${IMG_BASE_URL}/profile/${item?.handled_by?.profile_image}`} />
                                    <div>
                                        {item.status?.request_status && <StatusBox css={3} className='history_status' status={item.status?.request_status} />}
                                        <h6>{item['title' + _lang]}</h6>
                                        <p className='date'>{<DateViewer dateTime={item.createdAt} />}</p>
                                        <List css={2} items={item.actions_taken} />
                                        <p className='comment'>{item.comment}</p>
                                        <Files css={1} value={
                                            item.attachments.map(m => {
                                                return {
                                                    type: m.split('.').pop(),
                                                    url: `${IMG_BASE_URL}lecture_request/${m}`,
                                                    name: m
                                                }
                                            })
                                        } />
                                        {item?.voice_file && <VoicePlayer file={item?.voice_file} requestId={id} type='lecture_request' />}
                                    </div>
                                </>
                            )
                        }}
                    </List>
                </TitleBar>}

                {SectionConditions.comment && <TitleBar css={1} title='Comment' toggleButton>
                    <TextArea css={1} field='comment' inputSocket={formPlug} required validation={{ maxChar: { length: 2000 } }} />
                </TitleBar>}

                {SectionConditions.attachments && <FileUpload
                    css={1}
                    label=""
                    buttonLabel="Attach File"
                    maxFileSizeMB={50}
                    className='mt-4'
                    accept={{ items: ["jpg", "jpeg", "png", 'pdf', 'mp4'], message: 'Only jpg, jpeg, png, pdf and mp4 formats accepted' }}
                    field="attachments"
                    showPreview
                    multiple
                    icon={'/assets/icons/add.svg'}
                    inputSocket={formPlug}
                    loader={fileUploadLoader}
                />}

                <FooterButtons>
                    <Button right css={1} content="Back" icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate(-1)} />
                    {ButtonConditions.NotResponded && <Button left css={1} content="Not Responded" onClick={() => submitHandler(statuses.not_responded)} />}
                    {ButtonConditions.Reject && <Button left css={1} content="Reject" onClick={() => submitHandler(statuses.rejected)} />}
                    {ButtonConditions.Confirm && <Button left css={1} content="Confirm" onClick={() => submitHandler(statuses.confirm)} />}
                    {ButtonConditions.Cancel && <Button left css={1} content="Cancellation" onClick={() => { submitHandler(statuses.cancelled) }} />}
                    {ButtonConditions.Completed && <Button left css={1} content="Completed" onClick={() => { submitHandler(statuses.completed) }} />}
                </FooterButtons>

            </Page.Body>

        </Page>
    )
}