import React from "react"
import samples from "./common/samples.js"

import ProtectedRouter from "./_common/ProtectedRouter.jsx"
import Layout from "./_common/_components/Layout/Layout.jsx"
import Samples from "./_common/_components/Samples/Samples.jsx"
import SideBar from "./components/SideBar/SideBar.jsx"
import Header from "./components/Header/Header.jsx"

import Departments from "./pages/department/departments.jsx"
import UserListing from "./pages/manageUsers/userListing.jsx"
import AddUser from "./pages/manageUsers/addUser.jsx"
import ManageRequests from "./pages/requests/ManageRequests.jsx"
import AgentListing from "./pages/agent/AgentListing.jsx"
import ViewRequest from "./pages/requests/ViewRequest.jsx"
import AddRequest from "./pages/requests/AddRequest.jsx"
import Login from "./components/Login/Login.jsx"
import InitialApis from "./components/InitialApis.jsx"
import Reports from "./pages/Reports.jsx"
import IncidentTypes from "./pages/settings/IncidentTypes.jsx"
import LR_Requests from "./pages/lecture_requests/LR_requests.jsx"
import Dashboard_super_admin from "./pages/dashboard/dashboard_super_admin.jsx"
import Instagram from "./pages/social_media/Instagram.jsx"
import AddFeed from "./pages/social_media/AddFeed.jsx"
import LR_view_request from "./pages/lecture_requests/LR_view_request.jsx"
import LR_reports from "./pages/lecture_requests/LR_reports.jsx"
import UpcomingFeeds from "./pages/social_media/UpcomingFeeds.jsx"
import Notifications from "./pages/dashboard/Notifications.jsx"
import Alerts from "./pages/dashboard/Alerts.jsx"
import Translation from "./pages/settings/Translation.jsx"
import InstaFeeds from "./pages/dashboard/insta_feeds.jsx"
import Volunteers from "./pages/volunteers/Volunteers.jsx"
import Volunteers_detail from "./pages/volunteers/Volunteers_detail.jsx"
import Events_listing from "./pages/events/Events_listing.jsx"
import Event_detail from "./pages/events/Event_detail.jsx"
import Event_add from "./pages/events/Event_add.jsx"
import Assign_volunteers from "./pages/events/Assign_volunteers.jsx"
import Dashboard from "./pages/dashboard/Dashboard.jsx"
import Email from "./pages/settings/Email.jsx"

const routes = [
    {
        wrapper_component: <ProtectedRouter />,
        items: [
            {
                path: "login",
                component: <Login />,
            },
            {
                wrapper_component: <InitialApis />,
                items: [
                    {
                        wrapper_component: <Layout sidebar={<SideBar />} header={<Header />} />,
                        items: [
                            {
                                path: "samples",
                                component: <Samples content={samples} />,
                                icon: "/assets/icons/dashboard.svg"
                            },
                            {
                                label: 'Main Menu',
                                path: '',
                                icon: "/assets/icons/menus/dashboard.svg",
                                items: [
                                    // {
                                    //     path: "/1",
                                    //     label: 'Dashboard1',
                                    //     component: <Dashboard_super_visor />,
                                    //     icon: "/assets/icons/menus/dashboard.svg",
                                    // },
                                    {
                                        path: "/",
                                        label: 'Dashboard',
                                        // component: <Dashboard_super_admin />,
                                        component: <Dashboard />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            // {
                                            //     path: "notifications",
                                            //     component: <Notifications />
                                            // },
                                            // {
                                            //     path: "alerts",
                                            //     component: <Alerts />,
                                            // },
                                            {
                                                path: "instagram_feeds",
                                                component: <InstaFeeds />
                                            },
                                            {
                                                path: "status/:id",
                                                component: <ManageRequests isStatus />
                                            },
                                        ]
                                    },
                                    {
                                        path: "notifications",
                                        label: 'Notifications',
                                        component: <Notifications />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                    {
                                        path: "alerts",
                                        label: 'Alerts',
                                        component: <Alerts />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        access: 'super_admin',
                                    },
                                ]
                            },
                            {
                                label: 'Incident',
                                path: 'incident',
                                breadcrumb: 'Incident',
                                icon: "/assets/icons/menus/incident.svg",
                                items: [
                                    {
                                        path: "myrequests",
                                        label: 'My Requests',
                                        component: <ManageRequests isMyRequest />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                path: "view_request/:id",
                                                breadcrumb: 'Request',
                                                component: <ViewRequest />
                                            },
                                            {
                                                path: "add_request",
                                                breadcrumb: 'Add Request',
                                                component: <AddRequest />
                                            },
                                        ]
                                    },
                                    {
                                        path: "requests",
                                        label: 'Requests',
                                        component: <ManageRequests />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                path: "view_request/:id",
                                                breadcrumb: 'Request',
                                                component: <ViewRequest />
                                            },
                                            {
                                                path: "add_request",
                                                breadcrumb: 'Add Request',
                                                component: <AddRequest />
                                            },
                                        ]
                                    },
                                    {
                                        path: "reports",
                                        label: 'Reports',
                                        component: <Reports />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                ]
                            },
                            {
                                label: 'Lecture Request',
                                path: 'lecuture_request',
                                manage_lecture_request: true,
                                icon: "/assets/icons/menus/lecture_reports.svg",
                                items: [
                                    {
                                        path: "requests",
                                        label: 'Requests',
                                        component: <LR_Requests />,
                                        icon: "/assets/icons/menus/caret-left.svg",

                                        items: [
                                            {
                                                path: "view_request/:id",
                                                breadcrumb: 'Request',
                                                component: <LR_view_request />,
                                                icon: "/assets/icons/menus/caret-left.svg",
                                            }
                                        ]
                                    },
                                    {
                                        path: "reports",
                                        label: 'Reports',
                                        component: <LR_reports />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                ]
                            },
                            {
                                label: 'Team',
                                path: 'team',
                                // access: 'super_admin',
                                icon: "/assets/icons/menus/team.svg",
                                team_control: true,
                                items: [
                                    {
                                        path: "agent",
                                        label: "Agent",
                                        component: <AgentListing />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                    {
                                        path: "departments",
                                        label: "Departments",
                                        component: <Departments />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                    {
                                        path: "manage_users",
                                        label: 'Manage Users',
                                        component: <UserListing />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                path: "add_user",
                                                breadcrumb: 'Add User',
                                                component: <AddUser />
                                            },
                                            {
                                                path: "edit_user/:id",
                                                breadcrumb: 'Edit User',
                                                component: <AddUser />
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                label: 'Volunteers',
                                path: 'volunteer',
                                manage_volunteer_with_us: true,
                                icon: "/assets/icons/menus/volunteer.svg",
                                items: [
                                    {
                                        label: 'Volunteers',
                                        path: 'volunteers',
                                        component: <Volunteers />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                label: 'Volunteer Detail',
                                                path: 'volunteer_detail/:id',
                                                component: <Volunteers_detail />,
                                            },
                                            {
                                                label: 'Add Volunteer',
                                                path: 'volunteer_addRequest',
                                                component: <Volunteers_detail />,
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Events',
                                        path: 'events',
                                        component: <Events_listing />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                label: 'Event Details',
                                                path: 'event_detail/:id',
                                                component: <Event_detail />,
                                            },
                                            {
                                                label: 'Add Event',
                                                path: 'add_event',
                                                component: <Event_add />,
                                            },
                                            {
                                                label: 'Edit Event',
                                                path: 'edit_event/:id',
                                                component: <Event_add />,
                                            },
                                            {
                                                label: 'Assign Volunteers',
                                                path: 'assign_volunteers/:id',
                                                component: <Assign_volunteers />,
                                            },
                                        ],
                                    }
                                ]
                            },
                            {
                                label: 'Social Media',
                                path: 'social_media',
                                instagram_access: true,
                                icon: "/assets/icons/menus/social.svg",
                                items: [
                                    {
                                        path: "instagram",
                                        label: "Instagram",
                                        component: <Instagram />,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                        items: [
                                            {
                                                path: "add_feed",
                                                breadcrumb: 'Add Feed',
                                                component: <AddFeed />,
                                            },
                                            {
                                                path: "edit_feed/:id",
                                                breadcrumb: 'Edit Feed',
                                                component: <AddFeed isEdit />,
                                            },
                                            {
                                                path: "view_feed/:id",
                                                breadcrumb: 'View Feed',
                                                component: <AddFeed isView />,
                                            },
                                            {
                                                path: "upcoming_feeds",
                                                breadcrumb: 'Upcoming Feeds',
                                                component: <UpcomingFeeds />,
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                label: 'Settings',
                                path: 'settings',
                                // access: 'super_admin',
                                setting_control: true,
                                icon: "/assets/icons/menus/settings.svg",
                                items: [
                                    {
                                        path: "incident_types",
                                        label: "Incident Types",
                                        component: <IncidentTypes />,
                                        incident_types_control: true,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                    {
                                        path: "translation",
                                        label: "Translation",
                                        component: <Translation />,
                                        translation_control: true,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                    {
                                        path: "email",
                                        label: "Email",
                                        component: <Email />,
                                        email_setting_control: true,
                                        icon: "/assets/icons/menus/caret-left.svg",
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        wrapper_component: <Layout header={<Header />} type={1} />,
                        items: [
                            {
                                path: 'dashboard_viewer',
                                component: <Dashboard isViewer={true} />
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

export default routes