import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import useFetch from '../../_common/_Hooks/useFetch'
import { useNavigate, useParams } from 'react-router-dom'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import Grid from '../../_common/_components/Grid/Grid'
import Table from '../../_common/_components/Table/Table'
import Actions from '../../_common/_components/Actions/Actions'
import { CALL_BASE_URL, IMG_BASE_URL } from '../../config'
import Button from '../../_common/_components/Button/Button'
import Select from '../../_common/_components/Select/Select'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import List from '../../_common/_components/List/List'
import useTranslate from '../../_common/_Hooks/useTranslate'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import { useSelector } from 'react-redux'
import axios from 'axios'
import VoicePlayer from '../../components/VoicePlayer/VoicePlayer'

export default function Events_detail() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { t, _lang } = useTranslate()
    const user = useSelector(s => s.user)

    const assignments = [
        { label: 'Primary', value: 'primary' },
        { label: 'Backup', value: 'backup' },
    ]

    // *********************************************************************************************************************
    const { res: details_res, req: getDetail } = useFetch({
        endpoint: 'event/getEventDetail',
        method: 'get',
        type: 'params',
        selfCall: { id }
    })
    // *********************************************************************************************************************
    const { req: deleteVolunteer } = useFetch({
        endpoint: 'event/removeVolunteer',
        method: 'put',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const { res: history_res, req: getHistory } = useFetch({
        endpoint: 'event/getEventHistory',
        method: 'post',
        type: 'raw',
        selfCall: { id }
    })
    // *********************************************************************************************************************
    const { req: changeStatus } = useFetch({
        endpoint: 'event/statusChange',
        method: 'put',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: exportDetails } = useFetch({
        endpoint: 'event/getEventDetailExport',
        method: 'get',
        type: 'params',
        download: true
    })
    // *********************************************************************************************************************
    const details = details_res?.data
    const volunteers = details_res?.data.volunteers
    const history = history_res?.data
    // *********************************************************************************************************************

    function changeHandler(e, r) {
        if (r.assignment === e.value) return
        changeStatus({
            event: id,
            volunteer: r._id,
            status: e.value
        }, () => {
            getDetail(id)
            getHistory({ id })
        })
    }

    async function deleteHandler(data) {
        const { cancel } = await Modals.Confirm({ action: 'remove volunteer' })
        if (cancel) return
        deleteVolunteer({
            id,
            userId: data._id,
            assignment: data.assignment
        }, () => {
            getDetail(id)
            getHistory({ id })
        })
    }

    function callHandler(number, eventId, volunteerId) {
        const formdata = new FormData()
        formdata.append('phone_number', number.replace('+','00'))
        formdata.append('agent_id', user?.phone_number)
        formdata.append('ticket_id', eventId)
        formdata.append('odata', volunteerId)
        formdata.append('agent_name', user?.employee_id)
        formdata.append('type', 'event')
        axios.post(CALL_BASE_URL, formdata)
    }

    return (
        <Page className='Event_detail'>

            <Page.Header>
                <Title />
                <Breadcrumb />
                <Button css={3} content='Export' icon='/assets/icons/export.svg' onClick={() => exportDetails(id)} />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title={'Details'}>
                    <Grid columns={4}>
                        <LabelValue label='Title' value={details?.title} />
                        <LabelValue label='Date from' value={<DateViewer dateTime={details?.event_from} />} />
                        <LabelValue label='Date to' value={<DateViewer dateTime={details?.event_to} />} />
                    </Grid>
                    <hr />
                    <LabelValue label='Description' value={details?.description} />
                </TitleBar>
                <TitleBar css={1} title='Volunteers' titleEndElement={
                    new Date(details?.event_to) - new Date() > 0 && <Button css={'1.1.1'} content='Assign Volunteers' height={38} onClick={() => { navigate('/volunteer/events/assign_volunteers/' + id) }} />
                }>
                    <Table
                        className='evnt-detail-table'
                        css={1.1}
                        _css={{ search: 2, pagination: 1, entries: 1 }}
                        titles={[
                            { title: 'First Name', key: 'first_name' },
                            { title: 'Last Name', key: 'last_name' },
                            { title: 'Phone Number', key: 'phone_number', dir: 'ltr', d_align: 'right' },
                            { title: 'Email', key: 'email' },
                            { title: 'Emirates ID', key: 'eid' },
                            { title: 'Assignment', key: 'assignment' },
                            { title: '', key: 'action' }
                        ]}
                        content={volunteers?.map((r) => {
                            return {
                                _id: r._id,
                                first_name: r.first_name,
                                last_name: r.last_name,
                                // phone_number: r.phone_number,
                                phone_number: <LabelValue css={1} value={r?.phone_number ? ((r?.customer_contact_code || '+971') + ' ' + r?.phone_number) : '-'} isHyperlink valueIcon={'/assets/icons/call-green-round.svg'}
                                    onIconClick={() => callHandler((r?.customer_contact_code || '+971') + r?.phone_number, id, r._id)} />,
                                email: r.email,
                                eid: r.eid.length > 0 && <LabelValue valueIcon='/assets/icons/id.svg' iconOnly url={`${IMG_BASE_URL}volunteer/${r.eid}`} iconHeight={35} />,
                                assignment: ((new Date(details?.event_to) - new Date()) <= 0) ? r.assignment : <Select css={1} width={200} field='hi'
                                    content={{ op: assignments, lb: 'label', vl: 'value' }}
                                    value={r.assignment}
                                    onChange={(e) => changeHandler(e, r)}
                                />,
                                action: <Actions buttons={{ delete: '/assets/icons/user-x.svg' }}
                                    visibility={{ delete: (new Date(details?.event_to) - new Date()) > 0 }}
                                    tooltip={{ delete: 'Remove Volunteer' }}
                                    onDelete={() => { deleteHandler(r) }}
                                />,
                            }
                        })}
                        onRowClick={(e) => { navigate('/volunteer/volunteers/volunteer_detail/' + e._id) }}
                        hideFilters
                    />
                </TitleBar>

                {history?.length > 0 && <TitleBar css={1} title={'History'}>
                    <List css={1} items={history}>
                        {
                            (item) => {
                                return (
                                    <>
                                        <List.Bullet src={'/assets/icons/history2.svg'} />
                                        <div>
                                            <p className='mb-3'>{item['title' + _lang]}</p>
                                            <p className='evh_item'><span>{t('Handled By')}</span> : <span>{item.handled_by['first_name' + _lang]}</span></p>
                                            <p className='evh_item'><span>{t('Updated At')}</span> : <span>{<DateViewer dateTime={item.updatedAt} />}</span></p>
                                            {item?.voice_file && <VoicePlayer file={item?.voice_file} requestId={id} type='event' />}
                                        </div>
                                    </>
                                )
                            }
                        }
                    </List>
                </TitleBar>}

            </Page.Body>

            <FooterButtons>
                <Button right css={1} content="Back" icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate('/volunteer/events')} />
            </FooterButtons>

        </Page>
    )
}