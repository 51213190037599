import { Chart, registerables } from "chart.js"
import { Bar, Doughnut, Pie, PolarArea } from "react-chartjs-2"
import { useNavigate } from "react-router-dom"
import InstaGridItem from "../../components/InstaGridItem/InstaGridItem"
import DatePick from "../../_common/_components/DatePick/DatePick"
import Button from "../../_common/_components/Button/Button"
import TitleBar from "../../_common/_components/TitleBar/TitleBar"
import { useState } from "react"
import useTranslate from "../../_common/_Hooks/useTranslate"
import Table from "../../_common/_components/Table/Table"
import { IMG_BASE_URL } from "../../config"
import { useSelector } from "react-redux"
import Image from "../../_common/_components/Image/Image"

Chart.register(...registerables)
Chart.defaults.datasets.bar.maxBarThickness = 70

export const Total = ({ pos, data, onClick, clickable }) => {
    const { t } = useTranslate()
    return (
        <div id='Total' className={clickable ? 'clickable' : ''} onClick={onClick} >
            <img className="counts_img" src={data.icon} alt="" />
            <div>
                <p className="title">{t(data.title)} </p>
                <p className="count">{data.value}</p>
            </div>
        </div>
    )
}

export const CallDetails = ({ details }) => {
    const { t, _lang } = useTranslate()
    return (
        <div id="CallDetails">
            <img className="counts_img" src="/assets/icons/call.svg" alt="" />
            <div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text">{t("Voice Mail")}</th>
                            <th className="text">{t("Call Back")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text co">{t("Average Call Time")}</td>
                            <td>{Number(details?.avgVoiceCallRequest[0]?.totalAvgCallDuration || 0).toFixed(2)}</td>
                            <td>{Number(details?.avgCallBackRequest[0]?.totalAvgCallDuration || 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className="text co">{t("Average Res Time")}</td>
                            <td>{Number(details?.avgVoiceCallRequest[0]?.totalResponseTime || 0).toFixed(2)}</td>
                            <td>{Number(details?.avgCallBackRequest[0]?.totalResponseTime || 0).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const Counts = ({ details, viewerPermissions }) => {
    const navigate = useNavigate()
    const user = useSelector(s => s.user)
    return (
        <div id="Counts">
            {viewerPermissions.has_incident_details && <Total clickable={true} onClick={() => navigate('/incident/requests')} data={{ title: 'Incident Requests', value: details?.requestCount, icon: '/assets/icons/dash_incident.svg' }} />}
            {user?.manage_lecture_request && <Total clickable={user?.manage_lecture_request} onClick={() => user?.manage_lecture_request && navigate('/lecuture_request/requests')} data={{ title: 'Lecture Requests', value: details?.lectureCount, icon: '/assets/icons/dash_lecture.svg' }} />}
            {user?.manage_volunteer_with_us && <Total clickable={user?.manage_volunteer_with_us} onClick={() => user?.manage_volunteer_with_us && navigate('/volunteer/events')} data={{ title: 'Upcoming Events', value: details?.upcomingEvents, icon: '/assets/icons/dash_event.svg' }} />}
            {viewerPermissions.has_call_details && <CallDetails details={details} />}
        </div>
    )
}

export const ChartPolar = ({ counts, labels, keys, onClick }) => {
    const { t } = useTranslate()
    return (
        <div id="ChartPolar">
            <PolarArea
                options={{

                    plugins: {
                        legend: {
                            position: 'left',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    events: ['click'],
                    onClick: (a, b, c, d) => {
                        onClick && onClick({ request_type: keys[b[0]?.index] })

                    }
                }}
                data={{
                    labels: labels?.map(l => t(l)),
                    datasets: [
                        {
                            // label: '# of Votes',
                            pointBackgroundColor: 'red',
                            data: counts,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.5)',
                                'rgba(54, 162, 235, 0.5)',
                                'rgba(255, 206, 86, 0.5)',
                                'rgba(75, 192, 192, 0.5)',
                                'rgba(153, 102, 255, 0.5)',
                                'rgba(255, 159, 64, 0.5)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132)',
                                'rgba(54, 162, 235)',
                                'rgba(255, 206, 86)',
                                'rgba(75, 192, 192)',
                                'rgba(153, 102, 255)',
                                'rgba(255, 159, 64)',
                            ],
                            borderWidth: 1,
                            hoverOffset: 20
                        },
                    ],
                }} />
            <p>{t("Source")}</p>
        </div>
    )
}

export const ChartDoughnut = ({ counts, labels, keys, onClick }) => {
    const { t } = useTranslate()
    return (
        <div id="ChartDoughnut">
            <Doughnut
                options={{
                    layout: {
                        padding: {
                            right: 10
                            ,
                        }
                    },
                    plugins: {
                        legend: {
                            position: 'left',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    events: ['click'],
                    onClick: (a, b, c, d) => {
                        onClick && onClick({ incidentType: keys[b[0]?.index] })
                    }
                }}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            // label: '# of Votes',
                            data: counts,
                            backgroundColor: [
                                'rgba(136, 8, 8, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(153, 102, 64, 0.2)',
                                'rgba(102, 153, 64, 0.2)',
                                'rgba(206, 99, 153, 0.2)',
                                'rgba(99, 206, 162, 0.2)',
                                'rgba(162, 99, 206, 0.2)',
                            ],
                            borderColor: [
                                'rgba(135, 8, 8)',
                                'rgba(54, 162, 235)',
                                'rgba(255, 206, 86)',
                                'rgba(75, 192, 192)',
                                'rgba(153, 102, 255)',
                                'rgba(255, 159, 64)',
                                'rgba(153, 102, 64)',
                                'rgba(102, 153, 64)',
                                'rgba(206, 99, 153)',
                                'rgba(99, 206, 162)',
                                'rgba(162, 99, 206)',
                            ],
                            borderWidth: 1,
                            hoverOffset: 20,
                        },
                    ],
                }} />
            <p>{t("Incident Type")}</p>
        </div>
    )
}

export const ChartPie = ({ counts, labels, keys, onClick }) => {
    const { t } = useTranslate()
    const [count, setCount] = useState(0)
    return (
        <div id="ChartPie">
            <Pie
                options={{
                    layout: {
                        padding: {
                            right: 10,
                        }
                    },
                    plugins: {
                        legend: {
                            fullSize: true,
                            position: 'left',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    events: ['click'],
                    onClick: (a, b, c, d) => {
                        onClick && onClick({ department_id: keys[b[0]?.index] })
                        setCount(b[0]?.index)

                    }
                }}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            // label: '# of Votes',
                            data: counts || [],

                            backgroundColor: [
                                `rgba(49, 175, 222, .2)`,
                                'rgba(255, 187, 0, .2)',
                                'rgba(217, 82, 82, .2)',
                                'rgba(39, 168, 113, .2)',
                                'rgba(47, 32, 155, .2)',
                                'rgba(12, 103, 9, .2)',
                            ],
                            borderColor: [
                                'rgba(49, 175, 222, 1)',
                                'rgba(255, 187, 0, 1)',
                                'rgba(217, 82, 82, 1)',
                                'rgba(39, 168, 113, 1)',
                                'rgba(47, 32, 155, 1)',
                                'rgba(12, 103, 9, 1)',
                            ],
                            borderWidth: 1,
                            hoverOffset: 20,
                        },
                    ],
                }} />
            <p>{t("Department")}</p>
        </div>
    )
}

export const ChartBarV = ({ counts, labels, colors, bgColors }) => {
    const { t } = useTranslate()
    return (
        <div id="ChartBarV">
            <Bar
                options={{
                    indexAxis: 'x',
                    elements: {
                        bar: {
                            borderWidth: 2,
                        },
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Horizontal Bar Chart',
                        },
                    },
                }}
                data={{
                    labels: labels?.map(l => t(l)),
                    datasets: [
                        {
                            label: '',
                            data: counts,
                            // backgroundColor: [
                            //     'rgba(49, 175, 222, .2)',
                            //     'rgba(255, 187, 0, .2)',
                            //     'rgba(217, 82, 82, .2)',
                            //     'rgba(39, 168, 113, .2)',
                            //     'rgba(47, 32, 155, .2)',
                            //     'rgba(12, 103, 9, .2)',
                            //     'rgba(49, 175, 222, .2)',
                            //     'rgba(222, 112, 18, .2)',
                            //     'rgba(109, 153, 255, .2)',
                            // ],
                            backgroundColor: colors,
                            borderColor: bgColors,
                            // borderColor: [
                            //     '#31AFDE',
                            //     '#FFBB00',
                            //     '#D95252',
                            //     '#27A871',
                            //     '#2F209B',
                            //     '#0C6709',
                            //     '#31AFDE',
                            //     '#DE7012',
                            //     '#6D99FF',
                            // ],
                            borderRadius: 5,
                            borderSkipped: false,

                        },
                        // {
                        //     label: 'Dataset 2',
                        //     data: labels.map(() => Math.random() * 100),
                        //     borderColor: 'rgb(53, 162, 235)',
                        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        // },
                    ],
                }} />
            <p>{t("Incident Status")}</p>
        </div>
    )
}

export const ChartBarH = ({ counts, labels }) => {
    const { t } = useTranslate()
    return (
        <div id="ChartBarH">
            <Bar
                options={{
                    indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2,
                        },
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Horizontal Bar Chart',
                        },
                    },
                }}
                data={{
                    labels: labels?.map(l => t(l)),
                    datasets: [
                        // {
                        //     label: 'Dataset 1',
                        //     data: labels.map(() => Math.random() * 100),
                        //     borderColor: 'rgb(255, 99, 132)',
                        //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        // },
                        {
                            label: '',
                            data: counts,
                            borderRadius: 5,
                            borderSkipped: false,

                            backgroundColor: [
                                'rgba(70, 61, 128, .2)',
                                'rgba(26, 74, 206, .2)',
                                'rgba(217, 82, 82, .2)',
                                'rgba(224, 110, 37, .2)',
                                'rgba(111, 154, 26, .2)',
                                'rgba(217, 63, 63, .2)'
                            ],
                            borderColor: [
                                '#463D80',
                                '#1A4ACE',
                                '#D95252',
                                '#E06E25',
                                '#6F9A1A',
                                '#D93F3F'
                            ],

                        },
                    ],
                }} />
            <p>{t("Lecture Status")}</p>
        </div>
    )
}

export const InstagramFeeds = ({ data }) => {

    const { t } = useTranslate()
    const navigate = useNavigate()

    return (
        <div className="InstagramFeeds">
            <div className='head'>
                <p className='title'>{t("Upcoming Instagram Feed")}</p>
                <p className='view_all' onClick={() => navigate('instagram_feeds')}>{t("View All")}</p>
            </div>
            <div className='content'>
                {
                    data?.slice(0, 3).map((datom, i) => {
                        return <InstaGridItem key={i} data={datom} wholeData={data} index={i} />
                    })
                }
            </div>
        </div>
    )
}

export const Filter = ({ onFilter, form }) => {

    const { t } = useTranslate()
    const { inputs, formPlug, clearInputs, inputHandler } = form || {}

    return (
        <div id="Filter">
            <DatePick
                css={1}
                label='Date From'
                width={250}
                field={'startDate'}
                inputSocket={formPlug}
                onChange={(value, key) => {
                    inputHandler('', 'endDate')
                    inputHandler(value, key)
                }}
                maxDate={inputs.endDate}
                timeSelect
            />
            <DatePick
                css={1}
                label='Date To'
                width={250}
                field={'endDate'}
                inputSocket={formPlug}
                minDate={inputs.startDate}
                timeSelect
            />
            <Button
                css={1.1}
                mt={28}
                content='Filter'
                onClick={() => onFilter(inputs)}
            />
            <Button
                css={1.2}
                mt={28}
                content=''
                icon={'/assets/icons/close.svg'}
                iconWidth={15}
                onClick={() => { clearInputs(); onFilter() }}
                condition={inputs.endDate || inputs.startDate}
            />

            {/* <div id='duration' dir='ltr'>
                <p className='title'>Duration</p> : 7 Days
                <br />
                <p className='range'>Current : ( 22-04-2024 - 29-04-2024 ) <br />
                    Previous : ( 15-04-2024 - 22-04-2024 )</p>
            </div> */}
        </div>
    )
}

export const Agents = ({ data }) => {
    const { t, _lang } = useTranslate()
    return (
        <div id="Agents">
            {
                data?.map((datom, i) => {
                    return (
                        <div className='agent_item' key={i}>
                            <div className="head">
                                <Image className="agent_profile" width={50} src={`${IMG_BASE_URL}profile/${datom?.user_details?.profile_image}`} alt="" />
                                <div>
                                    <p className="name">{datom?.user_details?.['first_name' + _lang]}</p>
                                    <p className={"status " + (datom?.user_details?.is_online.toLowerCase() || 'available')}>{t(datom?.user_details?.is_online || 'Available')}</p>
                                </div>
                            </div>
                            <p className="detail">
                                <span className="key">{t('Avg Billed Call Duration')}</span>
                                <span className="colon">:</span>
                                <span className="value">{Number(datom?.avgBuildCallDuration).toFixed(2)}</span>
                            </p>
                            <p className="detail">
                                <span className="key">{t('Avg Call Duration')}</span>
                                <span className="colon">:</span>
                                <span className="value">{Number(datom?.avgCallDuration).toFixed(2)}</span>
                            </p>
                            <p className="detail">
                                <span className="key">{t('Count')}</span>
                                <span className="colon">:</span>
                                <span className="value">{datom?.count}</span>
                            </p>
                            <p className="detail">
                                <span className="key">{t('Responded Time')}</span>
                                <span className="colon">:</span>
                                <span className="value">{Number(datom?.responded_time).toFixed(2)}</span>
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const Calls = ({ data }) => {
    const { t } = useTranslate()
    return (
        <div id="Calls">
            {data?.map((datom, i) => {
                return (
                    <div className="call_item" key={i}>
                        <div>
                            <p className="title">{t(datom.title)}</p>
                            <p className="count">{datom.count}</p>
                            <img className="icon" width={40} src={datom.icon} alt="" />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}