import React from 'react'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Page from '../_common/_components/Page/Page'
import Table from '../_common/_components/Table/Table'
import DatePick from '../_common/_components/DatePick/DatePick'
import Select from '../_common/_components/Select/Select'
import StatusBox from '../_common/_components/StatusBox/StatusBox'
import Image from '../_common/_components/Image/Image'
import FilterBox from '../_common/_components/FilterBox/FilterBox'
import { IMG_BASE_URL } from '../config'
import DateViewer from '../_common/_components/DateViewer/DateViewer'
import useFilter from '../_common/_Hooks/useFilter'
import useTranslate from '../_common/_Hooks/useTranslate'
import ReportCounts from '../components/ReportCounts/ReportCounts'
import useFetch from '../_common/_Hooks/useFetch'

export default function Reports() {

    const { _lang } = useTranslate()

    const requestTypeData = [
        { label: 'Manual', value: 'manual' },
        { label: 'Call Back', value: 'call_back' },
        { label: 'Voice Mail', value: 'voice_mail' },
        { label: 'Mobile App', value: 'mobile_app' },
        { label: 'Website', value: 'website' },
        { label: 'Email', value: 'email' },
    ]

    // *********************************************************************************************************************
    const { req: getReports, res: report_res } = useFetch({
        endpoint: 'report/getRequestReport',
        method: 'post',
        type: 'formdata',
        toast: false,
    })
    // *********************************************************************************************************************
    const { res: department_res } = useFetch({
        endpoint: 'department/getDepartmentInRequest',
        method: "post",
        type: "formdata",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: country_res } = useFetch({
        endpoint: 'common/getFullCountries',
        method: "get",
        type: "raw",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: customers_res } = useFetch({
        endpoint: 'request/getCustomersInRequest',
        method: "post",
        type: "formdata",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: agents_res } = useFetch({
        endpoint: 'request/getAgentsInRequest',
        method: "post",
        type: "formdata",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: status_res } = useFetch({
        endpoint: 'common/getAllStatus',
        method: "get",
        type: "formdata",
        selfCall: true
    })
    // *********************************************************************************************************************
    const { req: exportReports } = useFetch({
        endpoint: 'report/getRequestReportExport',
        method: "post",
        type: "formdata",
        download: true
    })
    // *********************************************************************************************************************
    const { res: incidentTypes_res } = useFetch({
        endpoint: 'incidentType/getIncidentTypeInRequest',
        method: 'post',
        type: 'raw',
        selfCall: true,
        privateLoader: true
    })
    // *********************************************************************************************************************
    let reportData = report_res?.data
    let countData = report_res?.countData
    let pageDetails = report_res?.page
    let departments = department_res?.data
    let countries = country_res?.data
    let customers = customers_res?.data
    let agents = agents_res?.data
    let statuses_for_filter = ["resolved", "forward", "processing", "closed", "to_do", "assigned", "waiting_approval", "need_info", "escalated", "rejected", "approved", "reopen"]
    let allStatus = status_res?.data.filter(s => statuses_for_filter.includes(s.handle_key))
    let incidentTypes = incidentTypes_res?.data
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getReports,
        Export: exportReports
    });

    const data = {
        'Closed': countData?.closedCount,
        'Escalated': countData?.escalatedCount,
        'Forwarded': countData?.forwardCount,
        'Assigned': countData?.assignedCount,
        'Need Info': countData?.needInfoCount,
        'Processing': countData?.processingCount,
        'Rejected': countData?.rejectedCount,
        'Resolved': countData?.resolvedCount,
        'To Do': countData?.toDoCount,
        'Total': countData?.totalCount,
        'Waiting For Approval': countData?.waitingForApprovalCount,
        'Reopened': countData?.reopenedCount
    }

    return (
        <Page className='incident_reports'>

            <Page.Header>
                <Title title='Reports' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1} >
                <FilterBox
                    _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2, Export_Btn: 3 }}
                    _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                    showCloseBtns
                    filterHandler={filterHandler}
                    filterData={filterData}
                >
                    <DatePick label='Start Date' field='start_date' maxDate={(e) => e.end_date} />
                    <DatePick label='End Date' field='end_date' minDate={(e) => e.start_date} />
                    <Select label='Customer Name' field='customer' content={{ op: customers?.filter(a => a?.customer_name_en), lb: 'customer_name_en', vl: 'customer_name_en' }} />
                    <Select label='Department' field='department' content={{ op: departments, lb: 'department_name' + _lang, vl: '_id' }} />
                    <Select label='Country' field='country' content={{ op: countries, lb: 'name', vl: '_id' }} />
                    <Select label='Incident Type' field='incidentType' content={{ op: incidentTypes, lb: 'incident_type' + _lang, vl: '_id' }} />
                    <Select label='Request Type' field='request_type' content={{ op: requestTypeData, lb: 'label', vl: 'value' }} />
                    <Select label='Handled By' field='agent' content={{ op: agents, lb: 'first_name' + _lang, vl: '_id' }} />
                    <Select label='Status' field='status' content={{ op: allStatus, lb: 'request_status', vl: '_id' }} />
                </FilterBox>

                <ReportCounts data={data} />

                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Ticket Number', key: 'ticket_no', sort: true },
                        { title: 'Created Date', key: 'created_date', sort: true },
                        { title: 'Handled By', key: 'created_by', sort: true },
                        { title: 'Reporter Name', key: 'customer_name', sort: true },
                        { title: 'Phone Number', key: 'customer_contact', dir: 'ltr', d_align: 'right', sort: true },
                        { title: 'Email', key: 'customer_email', sort: true, d_align: 'right' },
                        { title: 'Request Type', key: 'request_type', sort: true },
                        { title: 'Incident Type', key: 'incident_type', sort: true },
                        { title: 'Assigned Department', key: 'department', sort: true },
                        { title: 'Status', key: 'status', sort: true }
                    ]}
                    content={reportData?.map(item => {
                        return ({
                            ticket_no: item.ticket_no,
                            created_date: <DateViewer dateTime={item.createdAt} dateOnly />,
                            created_by: item.handled_by
                                ? <Image src={`${IMG_BASE_URL}profile/${item.handled_by?.profile_image}`} label={item.handled_by?.['first_name' + _lang]} />
                                : '-',
                            customer_name: item[`customer_name${_lang}`],
                            customer_contact: item?.customer_contact ? ((item?.customer_contact_code || '+971') + ' ' + item?.customer_contact) : '-',
                            customer_email: item?.customer_email,
                            request_type: <StatusBox css={2} status={item?.request_type} />,
                            incident_type: item.incident_type?.[`incident_type${_lang}`],
                            department: item.department?.[`department_name${_lang}`],
                            status: <StatusBox css={1} status={item?.status?.request_status} />
                        })
                    })}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                />
            </Page.Body>
        </Page>
    )
}